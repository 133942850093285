<template>
  <el-collapse v-model="activeNames" style="width: 90%; margin: 0 auto;">
    <el-collapse-item name="0">
      <template slot="title"><h3>指导老师</h3></template>
      <el-row :gutter="10" style="width: 95%;">
        <el-col :span="teacherPhotoWidth">
          <div style="margin-top: 1.2rem">
            <img src="https://static.textualesg.com/avatar/yuhonghai.jpg"
                 style="max-width: 12rem;width: 100%; height: auto">
          </div>

        </el-col>
        <el-col :span="teacherIntroWidth">
          <div style="margin: 1rem">
            <p style="text-align: left; font-size: 1rem">
              俞红海，南京大学工程管理学院院长，教授、博士生导师，国家社科基金重大项目首席专家，兼任全国金融专业学位研究生教育指导委员会委员、金融科技教育与研究50人论坛成员、中国金融期货交易所市场发展咨询委员会委员等职。主要研究方向：行为金融、金融科技与量化投资等，具体领域包括投资者行为、投资者画像、ESG投资，以及IPO定价与询价制度改革、企业科技创新等。
            </p>
            <p style="text-align: left; font-size: 1rem">
              在“Management Science”、“Journal of Empirical Finance”、“Journal of Futures
              Markets”、《经济研究》、《管理世界》、《管理科学学报》和《金融研究》等高水平学术期刊发表论文数十篇，另有十余篇论文入选“45th EFA Annual Meeting(2018)”、“2017 FMA
              Annual Meeting”、“2010 American Economic Association”等高水平学术会议，出版学术专著两部、译著一部。
            </p>
            <p style="text-align: left; font-size: 1rem">
              主持国家社科基金重大项目1项：“中国机构投资者功能定位与监管政策研究”，主持国家自然科学基金项目4项，包括：“网络视角下的投资者交易行为及其影响研究”、“融资融券非对称交易行为及其影响研究——基于投资者信用账户数据”、“中国渐进式询价制度改革下的IPO定价与新股配售研究”和“管理层股权激励与公司行为及绩效——基于双重代理框架的研究”，同时参与了上海证券交易所重点项目“沪港通投资者行为和风险控制”和“2015年股票市场异常波动及其发生机理”等多个课题的研究工作。
            </p>
          </div>

        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item name="1">
      <template slot="title"><h3>金融算法组</h3></template>
      <el-row :gutter="10" style="width: 95%;">
        <el-col v-for="(financialCrew, index) in financialGroup" :key="index" :span=colWidth>
          <el-card :body-style="{ padding: '8px' }" style="text-align: center">
            <el-popover effect="light" placement="bottom-start" trigger="hover" width="20rem">
              <p slot="default" style="width: 80%; margin: 0 auto;">
                {{ financialCrew.intro }}
              </p>
              <div slot="reference" style="margin: auto">
                <el-avatar :src=financialCrew.photo
                           fit="scale-down">
                </el-avatar>
                <br>
                <span style="font-size: large">{{ financialCrew.name }}</span>
                <br>
                <span v-if="financialCrew.isLeader" v-html="financialCrew.tag">{{ financialCrew.tag }}</span>
              </div>
            </el-popover>
          </el-card>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item name="2">
      <template slot="title"><h3>商业组</h3></template>
      <el-row :gutter="10" style="width: 95%;">
        <el-col v-for="(businessCrew, index) in businessGroup" :key="index" :span=colWidth>
          <el-card :body-style="{ padding: '8px' }" style="text-align: center">
            <el-popover effect="light" placement="bottom-start" trigger="hover" width="20rem">
              <p slot="default" style="width: 80%; margin: 0 auto;">
                {{ businessCrew.intro }}
              </p>
              <div slot="reference" style="margin: auto">
                <el-avatar :src=businessCrew.photo
                           fit="scale-down">
                </el-avatar>
                <br>
                <span style="font-size: large">{{ businessCrew.name }}</span>
                <br>
                <span v-if="businessCrew.isLeader" v-html="businessCrew.tag">{{ businessCrew.tag }}</span>
              </div>
            </el-popover>
          </el-card>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item name="3">
      <template slot="title"><h3>技术指导</h3></template>
      <el-row :gutter="10" style="width: 95%;">
        <el-col v-for="(businessCrew, index) in supportGroup" :key="index" :span=colWidth>
          <el-card :body-style="{ padding: '8px' }" style="text-align: center">
            <el-popover effect="light" placement="bottom-start" trigger="hover" width="20rem">
              <p slot="default" style="width: 80%; margin: 0 auto;">
                {{ businessCrew.intro }}
              </p>
              <div slot="reference" style="margin: auto">
                <el-avatar :src=businessCrew.photo
                           fit="scale-down">
                </el-avatar>
                <br>
                <span style="font-size: large">{{ businessCrew.name }}</span>
                <br>
                <span v-if="businessCrew.isLeader" v-html="businessCrew.tag">{{ businessCrew.tag }}</span>
              </div>
            </el-popover>
          </el-card>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item name="4">
      <template slot="title"><h3>软件组</h3></template>
      <el-row :gutter="10" style="width: 95%;">
        <el-col v-for="(SDE, index) in SDEGroup" :key="index" :span=colWidth>
          <el-card :body-style="{ padding: '8px' }" style="text-align: center">
            <el-popover effect="light" placement="bottom-start" trigger="hover" width="20rem">
              <p slot="default" style="width: 80%; margin: 0 auto;">
                {{ SDE.intro }}
              </p>
              <div slot="reference" style="margin: auto">
                <el-avatar :src=SDE.photo
                           fit="scale-down">
                </el-avatar>
                <br>
                <span style="font-size: large">{{ SDE.name }}</span>
                <br>
                <span v-if="SDE.isLeader" v-html="SDE.tag">{{ SDE.tag }}</span>
              </div>
            </el-popover>
          </el-card>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      activeNames: ['0', '1', '2'],
      financialGroup: [
        {
          name: "张峻珲",
          photo: "https://static.textualesg.com/avatar/zhangjunhui.png",
          intro: "来自南京大学2019级计算机与金融工程实验班，任金工协会外联部部长、导师，学院羽毛球队队长，有一定的组织与管理能力；曾获卓越一等奖学金、人民一等奖学金、优秀共青团员等荣誉。有扎实的编程和金融基础，熟练运用C++、Python、R等数据开发与数据分析语言，曾在星环科技公司、世界资源研究所实习，有丰富的DS实战经验。热爱量化投资，曾参与MIT的交流项目中进行比特币价格预测并相关论文。在本项目中主要负责ESG指标构建的算法设计、策略回测分析，并参与文本的提取工作。",
          isLeader: true,
          tag: "团队<br>总负责人"
        },
        {
          name: "施宇",
          photo: "https://static.textualesg.com/avatar/shiyu.png",
          intro: "来自南京大学2019级计算机与金融工程实验班，曾担任学院副主席。曾获国家奖学金、卓越奖学金、优秀学生标兵、优秀共青团干部、全国大学生数学建模竞赛国家二等奖等荣誉。擅长使用计算机和数学建模解决金融问题，对金融科技方面有一定的研究经验。曾在远澜私募基金、星环科技大数据研究院、MIT对外交流项目中进行过信贷违约、买方量化等内容的实习和科研项目。在本项目中负责新闻文本提取和指标构建方面的算法设计、实现和部署等相关工作。",
          isLeader: true,
          tag: "新闻指标<br>负责人"
        },
        {
          name: "朱彤轩",
          photo: "https://static.textualesg.com/avatar/zhutongxuan.png",
          intro: " 来自南京大学2019级计算机与金融工程实验班，曾任工科试验班团学联副部长、毓琇书院副主席。曾获卓越奖学金、优秀共青团员、优秀学生等荣誉。具有良好的数理基础、扎实的编程能力，具备一定的金融素养，在券商研究所消费类组与金工组有过实习。在本项目中主要负责获取报告、文本处理、指标构建与计算等相关工作。",
          isLeader: true,
          tag: "其他文本<br>负责人"
        },
        {
          name: "李畅",
          photo: "https://static.textualesg.com/avatar/lichang.png",
          intro: "自南京大学2019级工程管理学院金融工程专业，专业排名第一，担任南京大学工程管理学院2019级年级长，曾任新媒体中心副部长、副班长等，有一定的组织能力；曾获国家奖学金，人民奖学金一等奖，杨咏曼奖学金等，南京大学优秀学生，优秀团员，社会实践优秀个人等荣誉称号。掌握Python、MATLAB、R等，且熟悉运用以上编程语言进行建模以及数据分析，曾获亚太杯数学建模大赛三等奖，美国大学生数学建模大赛M奖（一等奖）。参与大创项目，学术论文曾“银星杯”、基础学科论坛三等奖。在本项目中主要负责CSR报告、问答文本的结构化数据提取和指标构建的算法设计、实现以及整理工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "杨雪松",
          photo: "https://static.textualesg.com/avatar/yangxuesong.png",
          intro: "南京大学2019级计算机与金融工程实验班班长，南京大学金融工程协会主席，且曾获易方达杯ai+比赛二等奖，中国国际互联网＋大学生创新创业大赛省赛银奖，拥有发表专利两项（专利：深度学习反事实样本生成专利；专利：联邦学习数据质量评估专利）。曾在星环科技实习担任大数据研究员因果学习研究员。自主研究并提出创新的端到端深度学习自动驾驶模型，且参与深度学习竞争Q网络大学生创新项目研究与网络效应下定价优化创新项目研究。曾在一塔资本实习，负责量化策略开发，负责偏度策略、宏观择时策略、异常股票检索。擅长机器学习，在本项目中负责ESG策略编写。",
          isLeader: false,
          tag: ""
        },
        {
          name: "嵇泽同",
          photo: "https://static.textualesg.com/avatar/jizetong.png",
          intro: "南京大学19级计算机与金融工程实验班在读。曾获卓越奖学金、人民奖学金、菁英奖学金等。具备良好的计算机基础，熟悉掌握python、C++、Java等编程语言和Django、Vue、React等前后端框架，具有一定的数据库和数据处理基础和能力，同时具备一定的金融基础和素养，曾在星环科技实习。在本项目中参与数据获取及处理、数据库搭建及更新、文献阅读、新闻文本算法处理等工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "邵一淼",
          photo: "https://static.textualesg.com/avatar/shaoyimiao.png",
          intro: "南京大学计算机与金融工程专业2019级本科生，曾任南京大学团委志服部主任、工程管理学院团学联组织部长。熟悉Python、C++、Java、Matlab等开发与数据分析语言，掌握机器学习和自然语言处理技术，曾获卓越奖学金、人民奖学金、南京大学优秀共青团干部、校优秀学生等。在本项目中主要负责新闻文本处理、指标构建与计算、数据挖掘等工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "盛祺晨",
          photo: "https://static.textualesg.com/avatar/shengqichen.png",
          intro: "来自南京大学工程管理学院计算机与金融工程专业，曾获人民奖学金三等奖，菁英奖学金等，优秀团学干事、社会实践优秀个人等荣誉称号。曾在华泰证券投资部实习，掌握Python、C++、R等。在本项目中主要负责前期文献整理、文本指标计算、整理工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "姚心成",
          photo: "https://static.textualesg.com/avatar/yaoxincheng.png",
          intro: "南京大学19级金融工程专业。担任金融工程班级班长，曾担任团学联青年发展中心部长，获人民奖学金。掌握包括python、matlab、R语言等编程语言。担任国家级大创项目第一主持人。在本项目中参与新闻文本提取、指标构建以及数据标准化等方面的相关工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "孙立飞",
          photo: "https://static.textualesg.com/avatar/sunlifei.png",
          intro: "南京大学工程管理学院金融工程专业本科生，现任金工协会组织部部长，曾获人民奖学金，高教社杯全国大学生建模比赛省一，优秀团学干部。掌握Java、Python、R等编程语言，有一定的建模及数据处理能力。在团队中主要负责数据的搜集、处理、回测等。",
          isLeader: false,
          tag: ""
        },
        {
          name: "李锵",
          photo: "https://static.textualesg.com/avatar/liqiang.png",
          intro: "南京大学计算机与金融工程实验班2020级本科生，曾获卓越奖学金、南京大学新生学院优秀学生、南京大学优秀志愿者等荣誉。熟练掌握C++、Python等编程语言，具有扎实的数据分析与数学建模能力，曾获全国大学生数学建模竞赛江苏赛区一等奖。有自然语言处理与比特币挖矿模式分析等项目经历。曾实习于毕马威、世界资源研究所。在本项目中参与文本情感分析与ESG指标设计等工作。",
          isLeader: false,
          tag: ""
        }
      ],
      businessGroup: [
        {
          name: "张鼎周",
          photo: "https://static.textualesg.com/avatar/zhangdingzhou.png",
          intro: "南京大学商学院19级本科生，曾获人民奖学金，优秀团员，校十佳心理委员。现任校心协会长，曾任19商院团总支委员。曾在国海证券策略组，兴业证券汽车组，国金证券投行七部实习，累计与二级市场发表深度研究4篇，年度策略2篇，承做课题3个。对二级市场投资感兴趣，长期学习自上而下的投资框架。本次比赛中主要承担esg投资理念分析，市场分析及产品分析等框架性工作。",
          isLeader: true,
          tag: "商业组<br>负责人"
        },
        {
          name: "戴静怡",
          photo: "https://static.textualesg.com/avatar/daijingyi.png",
          intro: "来自南京大学2019级商学院财务管理专业，曾任商学院学生会副主席，国家奖学金获得者，参与团队曾获得十二届挑战杯创新创业大赛全国金奖，全国大学生金融精英挑战赛全国第一名及最具投资潜力奖，计算机设计大赛全国二等奖，花旗杯全国三等奖，互联网+省三，挑战杯红色赛道省一，黑科技赛道省二，双创省三等荣誉。国家级大创负责人，曾在国盛证券、东吴证券等机构实习，对商业计划书的撰写较有经验。",
          isLeader: false,
          tag: ""
        },
        {
          name: "陈文龙",
          photo: "https://static.textualesg.com/avatar/chenwenlong.png",
          intro: "南京大学商学院经济学专业本科生，现任商学院团委副书记，曾获国家奖学金、江苏省三好学生、江苏省优秀学生干部、南京大学优秀优秀学生等，获挑战杯大学生课外学术科技作品竞赛江苏省一等奖、江苏省\"三下乡\"社会实践优秀团队等，国家级大创负责人。掌握Python、R等。曾实习于中金公司、普华永道、江苏省经济和信息化研究院，在财务知识、政策认识、商业分析等方面积累了一定的认识。在本项目中主要负责商业分析、商业计划书的撰写等。",
          isLeader: false,
          tag: ""
        },
        {
          name: "王泽天",
          photo: "https://static.textualesg.com/avatar/wangzetian.png",
          intro: "南京大学商学院金融工程专业本科生，预备党员，曾获国家奖学金、人民奖学金一等奖、南京大学五四优秀共青团员、南京大学商学院优秀学生等，获挑战杯大学生课外学术科技作品竞赛江苏省一等奖，熟练掌握Python、R、MS、Office等计算机语言。曾实习于国海证券，在财务知识、政策认识、商业分析等方面积累了一定的认识。在本项目中参与负责商业分析、商业计划书撰写的工作等。",
          isLeader: false,
          tag: ""
        },
        {
          name: "孙文远",
          photo: "https://static.textualesg.com/avatar/sunwenyuan.png",
          intro: "南京大学商学院20级财务管理专业本科生。曾获人民奖学金、曙明奖学金、行知奖学金、校优秀学生、校优秀共青团员等荣誉。现任校心协副会长、商学院团委部门副主任。曾参与挑战杯、“互联网+”等多个大型商赛项目，在本项目负责商业计划书的撰写等。",
          isLeader: false,
          tag: ""
        }
      ],
      supportGroup: [
        {
          name: "林乐凡",
          photo: "https://static.textualesg.com/avatar/linlefan.png",
          intro: "南京大学工程管理学院金融硕士，曾获得全国大学生数学建模竞赛国家二等奖，美国大学生数学建模竞赛Finalist奖，中国高校计算机大赛------微信大数据挑战赛一等奖，华为杯中国研究生数学建模大赛三等奖，十五届\"花旗杯\"金融创新应用大赛第二名。在本项目中主要负责文本提取、新闻算法的设计指导工作，并完成获取文本数据、ESG指标构建的算法设计，参与文本指标提取的工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "李沛轩",
          photo: "https://static.textualesg.com/avatar/lipeixuan.png",
          intro: "南京大学数学系统计学16级本科生，工程管理学院管理科学与工程专业，金融工程方向20级硕士生。具有理工科背景，熟悉python、matlab等数据分析语言，同时掌握金融学知识。在本项目中主要负责文献阅读、商业计划书撰写、软件开发需求等相关工作的指导。",
          isLeader: false,
          tag: ""
        },
        {
          name: "黄玉豪",
          photo: "https://static.textualesg.com/avatar/huangyuhao.png",
          intro: "本科毕业于南京大学计算机与金融工程实验班2017级，现为南京大学计算机科学与技术系一年级直博生，研究方向为机器学习在金融领域的应用。曾获南京大学博士研究生校长特别奖学金。有丰厚的编程经验和扎实的金融基础，能熟练运用多种编程语言和机器学习算法框架，进行过复杂机器学习模型的开发实现。曾于图灵人工智能研究院、交叉信息核心技术研究院实习，参与量化投资相关研究；曾于南京银行实习，参与信贷风险管理模型开发。在本项目中主要负责前期文献调研，文本分析算法和ESG投资指导。",
          isLeader: false,
          tag: ""
        },
        {
          name: "雷欣南",
          photo: "https://static.textualesg.com/avatar/leixinnan.png",
          intro: "南京大学工程管理学院管理科学与工程2021级硕士，现任工程管理学院研究生会执行主席；曾获国家奖学金、江苏省优秀学生干部、江苏省优秀毕业生、南京大学优秀共青团员标兵、南京大学优秀学生会骨干、南京大学优秀学生等荣誉称号；曾获美国大学生数学建模竞赛Finalist、全国大学生数学建模竞赛二等奖、SAS（中国）高校数据分析大赛一等奖、“华为杯”中国研究生数学建模竞赛三等奖等赛事奖项；曾在中国银行、华泰证券等实习，研究兴趣为量化投资与基金行为，第一作者论文发表于《中国管理科学》。负责本项目后期指导。",
          isLeader: false,
          tag: ""
        },
        {
          name: "吴铭昊",
          photo: "https://static.textualesg.com/avatar/wuminghao.png",
          intro: "南京大学数学系统计学18级本科生，曾获人民奖学金一等奖、拔尖计划奖学金、南京大学优秀学生社团干部、南京大学优秀共青团员，具有数学、计算机、金融学复合背景，在本项目中主要负责算法设计、分本分析等的指导工作。",
          isLeader: false,
          tag: ""
        }
      ],
      SDEGroup: [
        {
          name: "余东骏",
          photo: "https://static.textualesg.com/avatar/yudongjun.png",
          intro: "南京大学软件学院2019级本科生，曾获郑钢菁英奖学金、人民奖学金一等奖、校级优秀学生、校级社会实践优秀学生和微软亚研院颁发的俱乐部之星等荣誉。现任南京大学微软学生俱乐部主席，曾任南京大学IT侠互助协会Geek组（维修组）组长，有丰富的活动组织与管理经验。热爱软件开发技术，了解前后端和数据库相关技术，有丰富的云计算产品相关经验，获得4项华为云微认证。在本项目中负责需求分析、文档撰写、软件开发过程管理、云平台选型，部署，运维与管理和DevOps实施落地等相关工作。",
          isLeader: true,
          tag: "软件组<br>负责人"
        },
        {
          name: "林瑞杰",
          photo: "https://static.textualesg.com/avatar/linruijie.png",
          intro: "南京大学19级软件工程专业本科生，曾获人民奖学金一等奖。具有较丰富的软件开发和管理经验，熟练掌握后端Web框架Spring，服务端运维和敏捷开发相关流程和工具，熟悉微服务架构设计和云计算应用。在本次项目中负责服务端架构设计，服务端代码编写等工作。",
          isLeader: true,
          tag: "后端<br>架构师"
        },
        {
          name: "石善羽",
          photo: "https://static.textualesg.com/avatar/shishanyu.png",
          intro: " 南京大学软件学院本科生，现任软件学院2019级3班班长，曾获青颖飞帆奖学金、福佑奖学金等，南京大学优秀学生、优秀共青团员、校社会实践优秀个人等荣誉称号。曾参与国家级大创项目、获江苏省数学竞赛（非数学类）一等奖、所带领的社会实践团队获校优秀团队称号。具有良好的组织能力、扎实的数理基础、较强的软件开发以及编程能力。在本项目中主要负责网页前端部分的搭建以及细化工作。",
          isLeader: true,
          tag: "前端<br>架构师"
        },
        {
          name: "钟山",
          photo: "https://static.textualesg.com/avatar/zhongshan.png",
          intro: "南京大学软件学院本科生。在校期间学习成绩优秀，曾获国家奖学金、南京大学优秀学生等荣誉。竞赛方面获得华东八校Hackthon决赛三等奖、全国大学生数学竞赛一等奖等。在本次项目中承担文档撰写以及部分后端构建工作。担任班级团支书，曾获得五四优秀团干部等奖项。",
          isLeader: true,
          tag: "DBA"
        },
        {
          name: "关昕宇",
          photo: "https://static.textualesg.com/avatar/guanxinyu.png",
          intro: "南京大学软件学院2019级本科生。在校期间学习成绩优秀，年级排名名列前茅，曾获国家奖学金、南京大学优秀学生等荣誉。掌握Java、Python、MySQL等编程语言以及SpringBoot、Flask等框架。扎实干练，沉稳勤勉。在项目中负责后端开发和云原生工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "余欣然",
          photo: "https://static.textualesg.com/avatar/yuxinran.png",
          intro: " 南京大学软件学院2019级本科生，曾获优秀学生、优秀共青团员、优秀志愿者、NITORI国际奖学金、人民奖学金一等奖等荣誉。熟悉Python、Java和JavaScript等编程语言及Vue、Springboot等主流开发框架，课内外项目开发经验丰富。在本项目中主要负责前端开发、需求文档撰写等工作。",
          isLeader: false,
          tag: ""
        },
        {
          name: "仇志佳",
          photo: "https://static.textualesg.com/avatar/qiuzhijia.png",
          intro: "南京大学19级商学院金融学本科生，南京大学IT侠互助协会社长助理。曾获人民奖学金二等奖，国泰人寿奖学金，EL比赛三等奖。熟练使用C++、python、R语言，努力将金融与人工智能技术结合。在本项目中负责软件中金融相关模块的实现。",
          isLeader: false,
          tag: ""
        },
      ],
      colWidth: 2,
      teacherPhotoWidth: 3,
      teacherIntroWidth: 21,
    }
  },
  methods: {
    calcSize(that) {
      if (window.innerWidth < 1200) {
        that.colWidth = 8;
        if (window.innerWidth < 600) {
          that.teacherPhotoWidth = 24
          that.teacherIntroWidth = 24
        } else {
          that.teacherPhotoWidth = 12
          that.teacherIntroWidth = 12
        }
      } else {
        that.colWidth = 2;
        that.teacherPhotoWidth = 6
        that.teacherIntroWidth = 18
      }
    }
  },
  mounted() {
    const that = this;
    this.calcSize(this);
    window.onresize = () => {
      return (() => {
        that.calcSize(that);
      })()
    }
  },
}
</script>

<style scoped>

</style>
